import React from 'react';
import './css/Banner.scss';
import './css/SearchField.scss';

import SearchField from './SearchField';

function Banner() {



  return (
    <div className='banner' role="img" aria-label="Textile Chemicals Manufacturers" title='Textile Chemicals Manufacturers'  >
      <div className='banner_inner'>
        <SearchField />
      </div>
    </div>
  );
}

export default Banner;
