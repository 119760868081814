import React from 'react'
import "./css/Industries.scss";

const Industries = () => {
    let mapData = ['Textile Industry', "Textile Industry", "Appliance Industry", "Aviation Industry", "Automotive Industry", "Electronics Industry", "Packaging Industry", "Industrial Manufacturing", "Homecare and Cleaning", "Agriculture", "Health and Personal Care", "Paints and Coatings"]
    return (
        <div className='supplementaryServices'>
            <div className='supplementaryInnerWrapper'>
                <div className='supplementaryHeader'>
                    <div className='supplementaryMegaTitle'>
                        Industries <span>We Serve</span>
                    </div>
                    <p className='supplementaryMegaContent'>
                        With our cutting-edge solutions and premium chemical products, we meet the demands of industries across the board.
                    </p>
                </div>

                <div className='mapItemContainer'>
                    {mapData.map((item, index) => {
                        return (
                            <div key={index} className='item'>{item}</div>
                        )
                    })}

                </div>



            </div>
        </div>
    )
}

export default Industries