import React, { useEffect, useRef, useState } from 'react'
import "./css/SupplementaryServices.scss";
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Slider from "../slider";

const SupplementaryServices = () => {
    const carouselRef = useRef(null);

    let mapData = [{
        title: 'Construction Chemicals',
        description: 'Our construction chemicals are designed to enhance the strength, durability, and performance of structures. ',
        link: '/product-category/construction-chemicals',
        image: '/assets/images/ConstructionChemicals.webp'
    }, {
        title: 'Textile Silicone Fluid',
        description: 'We offer premium textile silicone fluids that enhance fabric softness, elasticity, and smoothness. ',
        link: '/product-category/silicone-oil',
        image: '/assets/images/TextileSiliconeFluid.webp'
    }, {
        title: 'Momentive Amino Functional Fluid',
        description: 'The Momentive Amino Functional Fluids in our portfolio deliver superior results in fabric softening and finishing. ',
        link: '/product-category/momentive-amino-functional-fluid',
        image: '/assets/images/MomentiveAminoFunctionalFluid.webp'
    }, {
        title: 'Momentive Hydrophilic Oil',
        description: 'This product is ideal for enhancing moisture retention and breathability in textiles. ',
        link: '/product-category/hydrophilic-oil',
        image: '/assets/images/MomentiveHydrophilicOil.webp'
    }, {
        title: 'Emulsions/Softeners for Textile',
        description: 'Our emulsions and softeners are crafted to improve the texture and finish of textiles.',
        link: '/product-category/softeners-for-textile',
        image: '/assets/images/EmulsionsSoftenersforTextile.webp'
    }, {
        title: 'Epoxy',
        description: 'Known for their strength and resistance, our epoxy products are perfect for adhesives, coatings, and structural enhancements.',
        link: '/product-category/epoxy-flooring',
        image: '/assets/images/Epoxy.webp'
    }, {
        title: 'Surfactants',
        description: 'Our surfactants are designed to improve cleaning, wetting, and emulsifying efficiency. ',
        link: '/product-category/surfactant-chemicals',
        image: '/assets/images/Surfactants.webp'
    }, {
        title: 'Polydimethylsiloxane (PDMS)',
        description: 'This versatile silicone product is renowned for its flexibility, thermal stability, and lubrication properties. ',
        link: '/product-category/polydimethylsiloxane',
        image: '/assets/images/Polydimethylsiloxane.webp'
    }, {
        title: 'Other Portfolio',
        description: 'Beyond our core categories, we offer a broad spectrum of industrial chemicals, each chosen for effectiveness.',
        link: '/product-category/other-portfolio',
        image: '/assets/images/OtherPortfolio.webp'
    },]

    // flicktiy options

    const flickityOptions = {
        initialIndex: 1,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,  // Enables infinite loop
        contain: true,
        freeScroll: false, // Disables free scroll
        selectedAttraction: 0.2,
        friction: 0.8,
        lazyLoad: 1,
        imagesLoaded: true,
        autoPlay: 1500
    };

    // set flicty width state

    const [slideWidth, setSlideWidth] = useState("18%");

    // set flicty width

    useEffect(() => {
        console.log(window.screen.width);

        if (window.screen.width < 768) {
            setSlideWidth("90%");
            flickityOptions.wrapAround = false;
        }
    }, []);

    // flicktiy


    return (
        <div className='supplementaryServices'>
            <div className='supplementaryInnerWrapper'>
                <div className='supplementaryHeader'>
                    <div className='supplementaryMegaTitle'>
                        Our Product <span> Category</span>
                    </div>
                    <p className='supplementaryMegaContent'>
                        Hutaib Industrial Solutions offers a broad spectrum of top-tier chemicals, developed to meet the dynamic needs of multiple industries.
                    </p>
                </div>
                <div className='supplementaryCradWrapper showOnDesktop'>
                    {
                        mapData.map((item, index) => {
                            return (
                                <Link to={item.link} className='supplementaryCradItem' key={index}>
                                    <img src={item.image} alt="" />
                                    <div className='contentOverlay'>
                                        <p className='contentOverlayTitle'> {item.title} </p>
                                        <p className='contentOverlayDesc'>{item.description}</p>
                                        <p >View Project </p>
                                    </div>
                                    <p className='AbsTitle'>
                                        {item.title}
                                    </p>
                                </Link>
                            )
                        })
                    }
                </div>

                <div className='supplementaryCradWrapper showOnMobile'>


                    <Slider options={flickityOptions}>

                        {
                            mapData.map((item, index) => {
                                return (
                                    <Link to={item.link} className='supplementaryCradItem' key={index}
                                        style={{ width: slideWidth, height: "270px", margin: "5px 1%" }}
                                    >

                                        <img src={item.image} alt="" />
                                        <div className='contentOverlay'>
                                            <p className='contentOverlayTitle'> {item.title} </p>
                                            <p className='contentOverlayDesc'>{item.description}</p>
                                            <Link to={item.link}>View Project </Link>
                                        </div>
                                        <p className='AbsTitle'>
                                            {item.title}
                                        </p>
                                    </Link>
                                )
                            })
                        }


                    </Slider>

                    {/* <OwlCarousel className='owl-theme'
                        loop
                        margin={10}
                        items={1}  // Ensure only 1 item is shown
                        // autoplay
                        // dots={false}
                        // autoplayTimeout={2000}
                        stagePadding={0} // Remove extra padding
                        ref={carouselRef}>
                        {
                            mapData.map((item, index) => {
                                return (
                                    <div className='supplementaryCradItem' key={index}>
                                        <img src={item.image} alt="" />
                                        <div className='contentOverlay'>
                                            <p className='contentOverlayTitle'> {item.title} </p>
                                            <p className='contentOverlayDesc'>{item.description}</p>
                                            <Link to={item.link}>View Project </Link>
                                        </div>
                                        <p className='AbsTitle'>
                                            {item.title}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>; */}
                </div>
            </div>
        </div>
    )
}

export default SupplementaryServices